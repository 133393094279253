<template>
  <v-row
    id="facture"
    class="app-invoice-preview"
  >
    <v-col
      cols="12"
      md="9"
    >
      <v-card
        id="page"
        class="page"
      >
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-7 mb-sm-0 d-flex">
              <v-row>
                <v-col cols="4">
                  <img
                    alt="logo"
                    class="me-2"
                    contain
                    height="100px"
                    width="100px"
                    :src="logoGrossiste"
                  />
                </v-col>
                <v-col cols="8">
                  <span class="d-block text-bold text-uppercase">{{ clientInfo.short_app_name }}</span>
                  <span class="d-block text-bold">N° Cont. {{ clientInfo.gs_contribuable }}</span>
                  <span class="d-block text-bold">BP.:{{ clientInfo.gs_bp }}</span>
                  <span class="d-block text-bold">{{ clientInfo.gs_pays }}</span>
                  <span class="d-block text-bold">Tel.: {{ clientInfo.gs_tel }}</span>
                </v-col>
              </v-row>
            </div>
            <!-- Right Content -->
            <div class="align-center">
              <!--                            <p class="font-weight-medium text-xl text&#45;&#45;primary mb-4">-->
              <!--                              {{ appName }}-->
              <!--                            </p>-->
              <!--                            :style="{ 'background-image': 'url(' + dfinSignature + ')' }"-->
              <img
                alt="logo"
                class="me-2"
                contain
                height="100px"
                width="100px"
                :src="appLogo"
              />
              <h4>Le {{ moment().format('DD-MM-YYYY') }}</h4>
            </div>
          </div>
          <div class="d-flex justify-end pa-2">
            <div class="p-2 bordered">
              <h2 class="d-block text-bold">
                {{ customer.name }}
              </h2>
              <span class="d-block text-bold">{{ customer.tel }}</span>
              <span class="d-block text-bold">{{ customer.email }}</span>
            </div>
          </div>

          <div class="mt-8 mb-4">
            <h2 class="mb-4 text-decoration-none">
              {{ titre }} {{ debt.num_debt }}
            </h2>
            <h3 class="mb-4 text-decoration-none">
              OBJET : Abonnements du mois de {{ moment().set('month', parseInt(debt.month)-1).format('MMMM') }} {{ debt.year }}
            </h3>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Table -->
        <v-simple-table class="purchased-items-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="bordure bordureTop"
                  colspan="3"
                >
                  Désignation
                </th>
                <th class="bordure bordureTop">
                  Montant
                </th>
                <th class="bordure bordureTop bordure-droit">
                  Devise
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="text-no-wrap bordure"
                  colspan="3"
                >
                  Abonnement/réabonnement au bouquet CANAL+
                </td>
                <td class="text-left bordure">
                  {{ formatAmount(debt.amount) }}
                </td>
                <td class="text-left bordure bordure-droit">
                  {{ currency }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <h5>Voir le détail des opérations en annexe</h5>
        <br>
        <v-row>
          <v-col
            cols="6"
            class="p-2"
          >
            <v-simple-table>
              <template v-slot:default style='margin-left: 10px'>
                <thead>
                  <tr>
                    <th class="text-left border-left">
                      Taxe
                    </th>
                    <th class="text-left">
                      Base
                    </th>
                    <th class="text-left border-right">
                      Montant
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border">
                    <td class="border-left">
                      Droits d'accises 0%
                    </td>
                    <td class="border-left">
                      {{ withCurrency(taxes.montantSansDroit) }}
                    </td>
                    <td class="border-left border-right">
                      {{ withCurrency(taxes.sansDroit) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="border-left">
                      Droits d'accises 12.50%
                    </td>
                    <td class="border-left">
                      {{ withCurrency(taxes.montantAvecDroit) }}
                    </td>
                    <td class="border-left border-right">
                      {{ withCurrency(taxes.avecDroit) }}
                    </td>
                  </tr>
                  <tr class="border">
                    <td class="border-left">
                      TVA {{ clientInfo.gs_tva }}%
                    </td>
                    <td class="border-left">
                      {{ withCurrency(taxes.montantTva) }}
                    </td>
                    <td class="border-left border-right">
                      {{ withCurrency(taxes.tva) }}
                    </td>
                  </tr>
                  <tr class="border">
                    <td class="border-left border-bottom">
                      <b>Total taxes</b>
                    </td>
                    <td class="border-left border-bottom"></td>
                    <td class="border-left border-right border-bottom">
                      <b>{{ withCurrency(taxes.total) }}</b>
                    </td>
                  </tr>
                </tbody>
                <span style='font-size: 8px;font-style: italic'>discount= montant des formules HT * 3%</span>
              </template>
            </v-simple-table>
          </v-col>
          <v-col
            cols="6"
            class="p-2"
          >
            <v-simple-table class="border">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <b>montant HT</b><br>
                      <!--                    DA <br>-->
                      <!--                    TVA <br>-->
                      <b>Taxes</b>
                    </td>
                    <td>
                      <b>{{ withCurrency(montantFormuleHT+montantOptionHT) }} </b><br>
                      <!--                    {{  withCurrency(taxes.avecDroit) }}<br>-->
                      <!--                    {{  withCurrency(taxes.tva) }}<br>-->
                      <b>{{ withCurrency(taxes.total) }}</b>
                    </td>
                  </tr>

                  <tr>
                    <td>MONTANT TTC</td>
                    <td v-if="activatedIr">
                      {{ withCurrency(taxes.total + montantFormuleHT) }}
                    </td>
                    <td v-else>
                      {{ withCurrency(montant) }}
                    </td>
                  </tr>
                  <tr v-if="activatedIr">
                    <td>
                      IR ({{ clientInfo.gs_taux_ir }}%)<br>
                      TVA retenue à la source <br>
                    </td>
                    <td v-if="activatedIr">
                      {{ withCurrency(taxes.ir) }}<br>
                      {{ withCurrency(taxes.tva) }}<br>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Discount <sup>*</sup> ({{ bond.bonusActivationParameters }}%)<br>
<!--                      TOTAL OPTION-->
                      <h3><b>NET A PAYER</b></h3>
                    </td>
                    <td>
                      {{ withCurrency(Math.round((montantFormuleHT * bond.bonusActivationParameters) / 100 )) }}<br>
<!--                      {{ withCurrency(montantOption) }}<br>-->
                      <h3><b class="success--text"> {{ withCurrency(netPayable) }}</b></h3>
                    </td>
                  </tr>
                </tbody>

              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-card-text class="py-6 px-8  page-suivante">
          <div class="text-right pt-3">

            <h4>Facture éditée le {{ moment().format('DD-MM-YYYY') }}</h4>
            <img
              class="me-2"
              width="150px"
              :src="dfinSignature"
            />
            <img
              class="ml-n10"
              width="150px"
              :src="dfinCachet"
            />
            <br>
            <h4>Les Finances</h4>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-4 pt-4">
        <h1 class="text-center mb-8">
          Annexe justificative
        </h1>
        <!-- Table -->
        <v-simple-table class="purchased-items-table mb-12">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="bordure">
                  N°
                </th>
                <th class="bordure">
                  Local
                </th>
                <th class="bordure">
                  Emplacement - N° décodeur
                </th>
                <th class="bordure">
                  Formule
                </th>
                <th class="bordure">
                  Option
                </th>
                <th class="bordure bordure-droit">
                  Montant
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in decodeur"
                v-if="item.formula.pu !== undefined "
                :key="item.number"
              >
                <td class="text-no-wrap bordure">
                  {{ decodeur.indexOf(item)+1 }}
                </td>
                <td class="bordure">
                  {{ item.loaclName }}
                </td>
                <td class="bordure">
                  {{ item.location }} - {{ item.number }}
                </td>
                <td class="bordure">
                  {{ item.formula.label }}
                </td>
                <td class="bordure">
                  {{ item.option !== null?item.option.nom: "N/A" }}
                </td>
                <td class="text-right bordure bordure-droit">
                  {{ item.option !== null?withCurrency(parseInt(item.option.pu) + parseInt(item.formula.pu)): withCurrency(item.formula.pu) }}
                </td>
              </tr>
              <!--            <tr>-->
              <!--              <td>-->
              <!--                Devise-->
              <!--              </td>-->
              <!--              <td>-->
              <!--                <h2>XAF</h2>-->
              <!--              </td>-->
              <!--              <td-->
              <!--                colspan="3"-->
              <!--                class="text-right"-->
              <!--              >-->
              <!--                Montant à payer-->
              <!--              </td>-->
              <!--              <td class="bordure bordure-droit text-right">-->
              <!--                2180000-->
              <!--              </td>-->
              <!--            </tr>-->
            </tbody>
          </template>
        </v-simple-table>

        <br>

        <p class="mb-0 footer text-sm">
          Document généré par SYGALIN SAS  le {{ moment().format('LL à HH:m:s') }}
        </p>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      id="dont-see"
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-text>
          <!--          <v-btn-->
          <!--            color="primary"-->
          <!--            class="mb-3"-->
          <!--            block-->
          <!--            :disabled="payBtn"-->
          <!--            @click="dialog=true"-->
          <!--          >-->
          <!--            <v-icon-->
          <!--              class="me-2"-->
          <!--              left-->
          <!--            >-->
          <!--              {{ icons.mdiSendOutline }}-->
          <!--            </v-icon>-->
          <!--            <span>Payer</span>-->
          <!--          </v-btn>-->
          <!--          <v-btn-->
          <!--            class="mb-3"-->
          <!--            color="secondary"-->
          <!--            block-->
          <!--            outlined-->
          <!--            :disabled="downloadBtn"-->
          <!--            @click="downloadPDF"-->
          <!--          >-->
          <!--            Télécharger-->
          <!--          </v-btn>-->
          <v-btn
            class="mb-3"
            color="primary"
            block
            outlined
            :disabled="printBtn"
            @click="printInvoice"
          >
            <v-icon
              class="me-2"
              left
            >
              fas fa-print
            </v-icon>
            Imprimer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <pay-form
        :montant="netPayable"
        @finish="dialog=false"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ref, onBeforeMount, computed, reactive, nextTick,
} from '@vue/composition-api'
import store from '@/store'
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'
import VueBarcode, { props as montantFormule, props as amountCanDep } from 'vue-barcode'
// eslint-disable-next-line import/extensions
import PayForm from '@/views/caution/PayForm'
import themeConfig from '@themeConfig'
import Http from '@/helpers/http'
import { CACHET_DFIN_NAME, SIGNATURE_DFIN_NAME } from '@/helpers/constants/contrat'
import { useRouter } from '@core/utils'
import moment from 'moment'
import Vue from 'vue'
import useDynamicConstant from '@/helpers/useDynamicConstant'

Vue.prototype.moment = moment

export default {
  name: 'CautionBill',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    barcode: VueBarcode, PayForm,
  },
  setup() {
    const paymentDetails = ref({})

    const isInvoiceSendSidebarActive = ref(false)
    const isAddPaymentSidebarActive = ref(false)
    const clientInfo = JSON.parse(localStorage.getItem('clientInfo'))
    const bond = ref({})
    const amount = ref(0)
    const additionAmount = ref({})
    const loading = ref(true)
    const coef = ref(0)
    const discount = ref(0)
    let netPayable = ref(0)
    const amountToPay = ref(0)
    const dialog = ref(false)
    const page = ref(null)
    const customer = JSON.parse(localStorage.getItem('customer'))

    const { htValue } = useDynamicConstant()

    console.log('customer', customer)

    const { route } = useRouter()

    const ticket = ref(0)

    const showDispenseRestAmount = computed(() => (isNaN(netPayable.value) ? amountCanDep.value : (amountCanDep.value - netPayable.value)))

    const dfinSignature = ref('')
    const dfinCachet = ref('')
    const logoGrossiste = ref('')

    const setCachet = () => {
      const numDist = JSON.parse(localStorage.getItem('customer')).num_dist
      const params = { path: `signatures-grossistes/sygalin/${CACHET_DFIN_NAME}${numDist}.png` }
      Http.post('getFileUrl', params)
        .then(response => {
          dfinCachet.value = response
        })
        .catch(error => error)
    }

    // eslint-disable-next-line no-unused-vars
    const setSignature = () => {
      const numDist = JSON.parse(localStorage.getItem('customer')).num_dist
      const params = { path: `signatures-grossistes/sygalin/${SIGNATURE_DFIN_NAME}${numDist}.png` }
      Http.post('getFileUrl', params)
        .then(response => {
          dfinSignature.value = response
        })
        .catch(error => error)
    }

    const setlogo = () => {
      const numDist = JSON.parse(localStorage.getItem('customer')).num_dist

      const path = `sygalin/logo/${numDist}/logo.png`
      console.log('path', path)
      const params = { path }
      Http.post('getFileUrl', params)
        .then(response => {
          logoGrossiste.value = response
          console.log('logoGrossiste', logoGrossiste)
        })
        .catch(error => error)
    }

    const printBtn = ref(false)
    const downloadBtn = ref(false)

    const hasStartedGeneration = () => {
      printBtn.value = true
      downloadBtn.value = true
    }

    const hasGenerated = () => {
      printBtn.value = false
      downloadBtn.value = false
    }

    const getDeposit = () => {
      Http.get(`get-deposit-payment-information/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          if (response.status === 200) {
            bond.value = response.data
          }
        })
        .catch(error => { console.log(error) })
    }

    const filename = computed(() => `PROFORMA_${moment().format('DD-MM-YYYY')}_BOND.pdf`)

    const activatedIr = JSON.parse(localStorage.getItem('customer')).withholding_ir

    const userData = ref([])
    const titre = ref('')
    const montantFormuleHT = ref(0)
    const montantOption = ref(0)
    const montantOptionHT = ref(0)
    const isProforma = ref(false)
    const payments = ref([])
    const billMonth = ref([])
    const signedDate = ref('')
    const decoders = ref('')
    const taxes = ref({
      sansDroit: 0,
      avecDroit: 0,
      montantSansDroit: 0,
      montantAvecDroit: 0,
      montantTva: 0,
      tva: 0,
      ir: 0,
      total: 0,
    })

    const formatAmount = price =>
      // eslint-disable-next-line no-restricted-globals,implicit-arrow-linebreak
      `${Number(price).toLocaleString()}`

    // const formatAmount = unit => Number(unit).toLocaleString()

    onBeforeMount(() => {
      // eslint-disable-next-line no-use-before-define
      loadEtatDette()
      loadFormules()
      getDeposit()
      setlogo()
      setSignature()
      setCachet()
      console.log('route.value.params', route.value.params)
      console.log('route.value.deeeee', route.value.params.decList)
      if (route.value.params.param.num_debt !== undefined) {
        debt.value = route.value.params.param
        decList.value=route.value.params.decList
        titre.value = 'FACTURE N° '
      } else {
        titre.value = 'FACTURE N° '
        isProforma.value = true
        signedDate.value = moment().format('DD-MM-YYYY')

      }
    })

    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const { CURRENCY } = useDynamicConstant()

    const getMontant1 = () => {
      // eslint-disable-next-line no-undef
      montant.value = 0
      montantFormule.value = 0
      montantFormuleHT.value = 0
      montantOption.value = 0
      taxes.value = {
        sansDroit: 0,
        avecDroit: 0,
        montantSansDroit: 0,
        montantAvecDroit: 0,
        montantTva: 0,
        tva: 0,
        ir: 0,
        total: 0,
      }
      // eslint-disable-next-line no-use-before-define
      decodeur.forEach(item => {
        console.log(item)
        // eslint-disable-next-line radix,no-param-reassign,no-unused-expressions
        if (item.request_type_id === 1) {
          // eslint-disable-next-line no-param-reassign,radix
          item.Montant = (parseInt(item.formula.pu) + parseInt(item.option.pu)) * parseInt(item.duration)

          console.log(item.formula)

          const mtn = parseInt(item.formula.pu) * parseInt(item.duration)
          montantFormule.value += mtn

          console.log('params', mtn, item.formula.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)

          const ht = htValue(mtn, item.formula.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)
          montantFormuleHT.value += ht

          if (parseInt(item.formula.is_tax_subject)) {
            taxes.value.montantAvecDroit += ht
            taxes.value.avecDroit += Math.round((ht * parseFloat(clientInfo.gs_taux_droit_assise)) / 100)
          } else {
            taxes.value.montantSansDroit += ht
          }

          taxes.value.montantTva = taxes.value.montantAvecDroit + taxes.value.avecDroit + taxes.value.montantSansDroit
          taxes.value.tva = Math.round((taxes.value.montantTva * clientInfo.gs_tva) / 100)

          taxes.value.total = taxes.value.tva + taxes.value.avecDroit

         // if (parseInt(activatedIr)) taxes.value.ir = Math.round(((montantFormuleHT.value + taxes.value.total) * parseFloat(clientInfo.gs_taux_ir)) / 100)
          if (parseInt(activatedIr)) taxes.value.ir = Math.round(((montantFormuleHT.value + taxes.value.total) * parseFloat(clientInfo.gs_taux_ir)) / 100)
          montantOption.value += parseInt(item.option.pu) * parseInt(item.duration)
        } else {
          // eslint-disable-next-line no-param-reassign,radix
          // eslint-disable-next-line no-param-reassign
          item.Montant = sumAmountUpgrade(item.formula2.pu, item.formula.pu, item.option2.pu, item.DureeUpg.id)

          console.log('element', item)

          console.log('params', item.formula.pu, item.formula.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)
          console.log('params', item.formula2.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)

          const ht1 = htValue(item.formula.pu, item.formula.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)
          const ht2 = htValue(item.formula2.pu, item.formula2.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)

          const mtnHt = ((parseFloat(ht2 - ht1)) * parseInt(item.Duree))
          const mtnTTC = ((parseFloat(item.formula2.pu - item.formula.pu)) * parseInt(item.Duree))

          console.log('montant ht', montantFormuleHT.value)

          montantFormule.value += mtnTTC

          montantFormuleHT.value += mtnHt

          console.log('mtnHt', mtnHt)
          console.log('montant ht après', montantFormuleHT.value)

          if (parseInt(item.formula2.is_tax_subject)) {
            taxes.value.montantAvecDroit += mtnHt
            taxes.value.avecDroit += Math.round((mtnHt * parseFloat(clientInfo.gs_taux_droit_assise)) / 100)
          } else {
            taxes.value.montantSansDroit += mtnHt
          }

          taxes.value.montantTva = taxes.value.montantAvecDroit + taxes.value.avecDroit + taxes.value.montantSansDroit
          taxes.value.tva = Math.round((taxes.value.montantTva * clientInfo.gs_tva) / 100)

          taxes.value.total = taxes.value.tva + taxes.value.avecDroit

          if (parseInt(activatedIr)) taxes.value.ir = Math.round(((montantFormuleHT.value) * parseFloat(clientInfo.gs_taux_ir)) / 100)
          //if (parseInt(activatedIr)) taxes.value.ir = Math.round(((montantFormuleHT.value + taxes.value.total) * parseFloat(clientInfo.gs_taux_ir)) / 100)

          // taxes.value.ir = Math.round((netPayable.value*2.2)/100)

          console.log('tva', taxes.value)

          // tva.value  = tva.value + (mtnTTC - mtnHt)

          montantOption.value += parseFloat(item.option2.pu) * parseInt(item.Duree)
        }

        // // refreshEndDate(item)
        // if (remise.value.active) {
        //   // eslint-disable-next-line radix
        //   montant.value += (parseInt(item.Montant) - ((parseInt(item.Montant) * 0.03)))
        // } else {
        // eslint-disable-next-line radix,no-use-before-define
        montant.value += (parseInt(item.Montant))

        // }
      })

      console.log('montantFormule.value', montantFormule.value)
      console.log('montantFormuleHT.value', montantFormuleHT.value)
      console.log('montantOption.value', montantOption.value)
      console.log('montant.value', montant.value)
    }
    const getMontant = () => {
      montant.value = 0
      montantFormule.value = 0
      montantFormuleHT.value = 0
      montantOptionHT.value = 0
      montantOption.value = 0
      taxes.value = {
        sansDroit : 0,
        avecDroit : 0,
        montantSansDroit : 0,
        montantAvecDroit : 0,
        montantTva : 0,
        tva : 0,
        ir : 0,
        total : 0,
      }
      decodeur.forEach(item => {
        // eslint-disable-next-line radix,no-param-reassign,no-unused-expressions
        //if (item.operation.id === 1) {
        if (item.request_type_id === 1) {
          // eslint-disable-next-line no-param-reassign,radix
          item.Montant = (parseInt(item.formula.pu) + parseInt(item.option.pu)) * parseInt(item.duration)

          console.log(item.formula)

          let mtn = parseInt(item.formula.pu) * parseInt(item.duration)
          montantFormule.value += mtn

          let mtnOption = parseInt(item.option.pu) * parseInt(item.duration)
          montantOption.value += parseInt(item.option.pu) * parseInt(item.duration)


          console.log('params', mtn, item.formula.is_tax_subject,clientInfo.gs_tva,clientInfo.gs_has_orther_tax,clientInfo.gs_tva_with_tax,activatedIr,clientInfo.gs_taux_ir,clientInfo.gs_taux_droit_assise)

          let ht = htValue(mtn, item.formula.is_tax_subject,clientInfo.gs_tva,clientInfo.gs_has_orther_tax,clientInfo.gs_tva_with_tax,activatedIr,clientInfo.gs_taux_ir,clientInfo.gs_taux_droit_assise)
          let htOption = htValue(mtnOption, 0,clientInfo.gs_tva,clientInfo.gs_has_orther_tax,clientInfo.gs_tva_with_tax,activatedIr,clientInfo.gs_taux_ir,clientInfo.gs_taux_droit_assise)

          montantFormuleHT.value += ht
          montantOptionHT.value += htOption

          if(parseInt(item.formula.is_tax_subject)){
            taxes.value.montantAvecDroit += ht
            taxes.value.avecDroit += Math.round((ht*parseFloat(clientInfo.gs_taux_droit_assise))/100)

          }
          else{
            taxes.value.montantSansDroit += ht
          }

          taxes.value.montantSansDroit += htOption

          taxes.value.montantTva = taxes.value.montantAvecDroit + taxes.value.avecDroit + taxes.value.montantSansDroit
          taxes.value.tva = Math.round((taxes.value.montantTva*clientInfo.gs_tva)/100)

          taxes.value.total = taxes.value.tva + taxes.value.avecDroit

          if(parseInt(activatedIr))
            taxes.value.ir = Math.round(((montantOptionHT.value + montantFormuleHT.value)*parseFloat(clientInfo.gs_taux_ir))/100)
          // taxes.value.ir = Math.round((netPayable.value*2.2)/100)


          console.log('tva',taxes.value)
          console.log('mtn',mtn)
          console.log('ht',ht)

        } else {
          // eslint-disable-next-line no-param-reassign,radix
          // eslint-disable-next-line no-param-reassign
          item.Montant = sumAmountUpgrade(item.formula2.pu, item.formula.pu, item.option2.pu, item.DureeUpg.id)

          console.log("element",item)

          // let ht1 = htValue(item.formula.pu, item.formula.is_tax_subject,clientInfo.gs_tva,clientInfo.gs_has_orther_tax,clientInfo.gs_tva_with_tax,activatedIr,clientInfo.gs_taux_ir,clientInfo.gs_taux_droit_assise)
          // let ht2 = htValue(item.formula2.pu, item.formula2.is_tax_subject,clientInfo.gs_tva,clientInfo.gs_has_orther_tax,clientInfo.gs_tva_with_tax,activatedIr,clientInfo.gs_taux_ir,clientInfo.gs_taux_droit_assise)

          // let mtnHt = ((parseFloat(ht2 - ht1)) * parseInt(item.DureeUpg.id))
          let mtnTTC = ((parseFloat(item.formula2.pu - item.formula.pu)) * parseInt(item.duration))

          let mtnHt = htValue(mtnTTC, item.formula2.is_tax_subject,clientInfo.gs_tva,clientInfo.gs_has_orther_tax,clientInfo.gs_tva_with_tax,activatedIr,clientInfo.gs_taux_ir,clientInfo.gs_taux_droit_assise)


          let  mtnOption = parseFloat(item.option2.pu) * parseInt(item.duration)

          let mtnOptionHt = htValue(mtnOption, 0,clientInfo.gs_tva,clientInfo.gs_has_orther_tax,clientInfo.gs_tva_with_tax,activatedIr,clientInfo.gs_taux_ir,clientInfo.gs_taux_droit_assise)

          montantFormule.value += mtnTTC

          montantFormuleHT.value += mtnHt

          montantOptionHT.value += mtnOptionHt

          if(parseInt(item.formula2.is_tax_subject)){
            taxes.value.montantAvecDroit += mtnHt
            taxes.value.avecDroit += Math.round((mtnHt*parseFloat(clientInfo.gs_taux_droit_assise))/100)
          }
          else{
            taxes.value.montantSansDroit += mtnHt
          }

          taxes.value.montantSansDroit += mtnOptionHt

          taxes.value.montantTva = taxes.value.montantAvecDroit + taxes.value.avecDroit + taxes.value.montantSansDroit
          taxes.value.tva = Math.round((taxes.value.montantTva*clientInfo.gs_tva)/100)

          taxes.value.total = taxes.value.tva + taxes.value.avecDroit

          if(parseInt(activatedIr))
            taxes.value.ir = Math.round(((montantOptionHT.value + montantFormuleHT.value + taxes.value.total)*parseFloat(clientInfo.gs_taux_ir))/100)
          // taxes.value.ir = Math.round((netPayable.value*2.2)/100)


          console.log('tva',taxes.value)

          // tva.value  = tva.value + (mtnTTC - mtnHt)

          montantOption.value += parseFloat(item.option2.pu) * parseInt(item.Duree.id)
        }

        // // refreshEndDate(item)
        // if (remise.value.active) {
        //   // eslint-disable-next-line radix
        //   montant.value += (parseInt(item.Montant) - ((parseInt(item.Montant) * 0.03)))
        // } else {
        // eslint-disable-next-line radix
        montant.value += (parseInt(item.Montant))

        // }
      })

      console.log("montantFormule.value",montantFormule.value)
      console.log("montantFormuleHT.value",montantFormuleHT.value)
      console.log("montantOptionHT.value",montantOptionHT.value)
      console.log("montantOption.value",montantOption.value)
      console.log("montant.value",montant.value)

      //forceRender()
    }
    const getMontant2 = () => {
      montant.value = 0
      montantFormule.value = 0
      montantFormuleHT.value = 0
      montantOption.value = 0
      taxes.value = {
        sansDroit: 0,
        avecDroit: 0,
        montantSansDroit: 0,
        montantAvecDroit: 0,
        montantTva: 0,
        tva: 0,
        ir: 0,
        total: 0,
      }
      decodeur.forEach(item => {
        // eslint-disable-next-line radix,no-param-reassign,no-unused-expressions
        if (item.operation.id === 1) {
          // eslint-disable-next-line no-param-reassign,radix
          item.Montant = (parseInt(item.formula.pu) + parseInt(item.option.pu)) * parseInt(item.Duree.id)

          console.log(item.formula)

          const mtn = parseInt(item.formula.pu) * parseInt(item.Duree.id)
          montantFormule.value += mtn

          console.log('params', mtn, item.formula.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)

          const ht = htValue(mtn, item.formula.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)
          montantFormuleHT.value += ht

          if (parseInt(item.formula.is_tax_subject)) {
            taxes.value.montantAvecDroit += ht
            taxes.value.avecDroit += Math.round((ht * parseFloat(clientInfo.gs_taux_droit_assise)) / 100)
          } else {
            taxes.value.montantSansDroit += ht
          }

          taxes.value.montantTva = taxes.value.montantAvecDroit + taxes.value.avecDroit + taxes.value.montantSansDroit
          taxes.value.tva = Math.round((taxes.value.montantTva * clientInfo.gs_tva) / 100)

          taxes.value.total = taxes.value.tva + taxes.value.avecDroit

          if (parseInt(activatedIr)) taxes.value.ir = Math.round(((montantFormuleHT.value + taxes.value.total) * parseFloat(clientInfo.gs_taux_ir)) / 100)

          // taxes.value.ir = Math.round((netPayable.value*2.2)/100)

          console.log('tva', taxes.value)
          console.log('mtn', mtn)
          console.log('ht', ht)

          montantOption.value += parseInt(item.option.pu) * parseInt(item.Duree.id)
        } else {
          // eslint-disable-next-line no-param-reassign,radix
          // eslint-disable-next-line no-param-reassign
          item.Montant = sumAmountUpgrade(item.formula2.pu, item.formula.pu, item.option2.pu, item.DureeUpg.id)

          console.log('element', item)

          const ht1 = htValue(item.formula.pu, item.formula.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)
          const ht2 = htValue(item.formula2.pu, item.formula2.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)

          const mtnHt = ((parseFloat(ht2 - ht1)) * parseInt(item.DureeUpg.id))
          const mtnTTC = ((parseFloat(item.formula2.pu - item.formula.pu)) * parseInt(item.DureeUpg.id))

          montantFormule.value += mtnTTC

          montantFormuleHT.value += mtnHt

          if (parseInt(item.formula2.is_tax_subject)) {
            taxes.value.montantAvecDroit += mtnHt
            taxes.value.avecDroit += Math.round((mtnHt * parseFloat(clientInfo.gs_taux_droit_assise)) / 100)
          } else {
            taxes.value.montantSansDroit += mtnHt
          }

          taxes.value.montantTva = taxes.value.montantAvecDroit + taxes.value.avecDroit + taxes.value.montantSansDroit
          taxes.value.tva = Math.round((taxes.value.montantTva * clientInfo.gs_tva) / 100)

          taxes.value.total = taxes.value.tva + taxes.value.avecDroit

          if (parseInt(activatedIr)) taxes.value.ir = Math.round(((montantFormuleHT.value + taxes.value.total) * parseFloat(clientInfo.gs_taux_ir)) / 100)

          // taxes.value.ir = Math.round((netPayable.value*2.2)/100)

          console.log('tva', taxes.value)

          // tva.value  = tva.value + (mtnTTC - mtnHt)

          montantOption.value += parseFloat(item.option2.pu) * parseInt(item.Duree.id)
        }

        // // refreshEndDate(item)
        // if (remise.value.active) {
        //   // eslint-disable-next-line radix
        //   montant.value += (parseInt(item.Montant) - ((parseInt(item.Montant) * 0.03)))
        // } else {
        // eslint-disable-next-line radix
        montant.value += (parseInt(item.Montant))

        // }
      })

      console.log('montantFormule.value', montantFormule.value)
      console.log('montantFormuleHT.value', montantFormuleHT.value)
      console.log('montantOption.value', montantOption.value)
      console.log('montant.value', montant.value)

      forceRender()
    }

    const currency = ref(CURRENCY)

    // eslint-disable-next-line no-shadow
    const sumAmountUpgrade = (formulePrice2, formulePrice, optionPrice, Duree) => {
      // eslint-disable-next-line no-unused-expressions,radix
      if (formulePrice !== formulePrice2) {
        // eslint-disable-next-line radix
        return ((parseFloat(formulePrice2 - formulePrice) + parseFloat(optionPrice)) * parseInt(Duree))
      }

      // eslint-disable-next-line radix,
      return parseFloat(optionPrice) * parseInt(Duree)
    }

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const withCurrency = price => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(price)) {
        return 0 + CURRENCY
      }

      return `${Number(price).toLocaleString()} ${CURRENCY}`
    }

    const printInvoice = () => {
      // eslint-disable-next-line no-global-assign
      window.print()
    }

    const debt = ref([])
    const decList = ref([])

    const montant = ref(0)

    const contrat = JSON.parse(localStorage.getItem('defaultContrat'))

    netPayable = computed(() => montantFormule.value - Math.round((montantFormuleHT.value * bond.value.bonusActivationParameters) / 100) + montantOption.value)

    const loadEtatDette = () => {
      Http.get(`customer-segment-debt/${contrat.pivot.customer_segment_id}`)
        .then(response => {
          if (response.status === 200) {
            userData.value = Object.values(response.data.statementDebts)
            payments.value = Object.values(response.data)
            billMonth.value = response.data.billsMonth
            getDecodeur()
          }
        })
        .catch(error => console.log(error))
    }

    const decodeur = reactive([])

    const getDecodeur = () => {
      console.log('get decoder')
      //console.log(route.value.params.decList[0].number)
      console.log(decList)
      for (let i = 0; i < userData.value.length; i++) {
        const details = userData.value[i].Details
        if (details.length) {
          details.forEach(item => {
            decodeur.push(item)
          })
        }
      }
      console.log("liste deodeur")
      console.log(decodeur)

      decodeur.forEach(item => {
        console.log(item.decoder_id)


        // eslint-disable-next-line no-param-reassign,no-shadow
        // item.formula = formule.value.filter(i => i.code === item.formula).length === 0 ? formule.value.filter((i, index) => index === 2)[0] : formule.value.filter(i => i.code === item.formula)
        item.formula = formule.value.filter(i => i.code === item.formula1).length === 0 ? formule.value.filter((i, index) => index === 2)[0] : formule.value.filter(i => i.code === item.formula1)[0]
        // eslint-disable-next-line no-param-reassign,no-shadow
        // item.option = option.value.filter(i => i.code === item.option).length === 0 ? option.value.filter((i, index) => index === 0)[0] : option.value.filter(i => i.code === item.option)
        //item.option = option.value.filter(i => i.code === item.option1).length === 0 ? option.value.filter((i, index) => index === 0)[0] : option.value.filter(i => i.code === item.option1)[0]
        item.option = option.value.filter(i => i.code === item.option1).length === 0 ?{
          alpha2: 'CM',
          code: '',
          id: 0,
          nom: 'Aucune',
          pu: 0,
        } : option.value.filter(i => i.code === item.option1)[0]
        // eslint-disable-next-line no-param-reassign,no-shadow,prefer-destructuring
        item.Duree = item.duration
        const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
        const endTime = moment(item.end_subscription).format('YYYY-MM-DD')

        if (moment(today).diff(endTime, 'days') < 0) {
          // eslint-disable-next-line no-param-reassign,no-shadow
          item.startTime = moment().format('YYYY-MM-DD')
        } else if (moment(today).diff(endTime, 'days') < 0) {
          // eslint-disable-next-line no-param-reassign,no-shadow
          item.startTime = moment(moment().format('YYYY-MM-DD')).add(1, 'days')
        } else {
          // eslint-disable-next-line no-param-reassign,no-shadow
          item.startTime = moment(endTime).add(1, 'days').format('YYYY-MM-DD')
        }
        // eslint-disable-next-line no-param-reassign,no-shadow
        item.activationDate = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')

        // eslint-disable-next-line no-param-reassign,no-shadow
        item.formula2 = formule.value.filter(i => i.code === item.formula2).length === 0 ? formule.value.filter((i, index) => index === 2)[0] : formule.value.filter(i => i.code === item.formula2)[0]

        // item.formula2 = item.formulaFormated
        // eslint-disable-next-line no-param-reassign,no-shadow
        item.option2 = option.value.filter(i => i.code === item.option2).length === 0 ? option.value.filter((i, index) => index === 0)[0] : option.value.filter(i => i.code === item.option2)[0]

        // eslint-disable-next-line no-param-reassign
        item.reabo_auto = item.is_auto === 1
        // eslint-disable-next-line no-use-before-define

        // const remainingTime = Math.ceil(moment.duration(moment(endTime).diff(moment(today), 'days')).asMonths())
        const diffDays = moment(endTime).diff(moment(today), 'days')
        const remainingTime = (diffDays > 0) ? Math.round(diffDays / month) : 0
        // eslint-disable-next-line no-param-reassign
        item.DureeUpg = { id: remainingTime, itemTitle: `${remainingTime} mois` }
        // route.value.params.decList
        if (route.value.params.decList!=undefined){
          let data=route.value.params.decList.filter(function(number) {
            return number.id == item.decoder_id;
          })

          item.number=data[0].number
          item.location=data[0].location
          item.loaclName=data[0].local_name
        }


      })
      console.log('decodeur.value', decodeur)
      getMontant()
    }

    const formule = ref([{}])

    const option = ref([{}])

    const forceRender = () => {
      showSomething.value = false
      nextTick(() => {
        // Okay, now that everything is destroyed, lets build it up again
        // eslint-disable-next-line no-plusplus
        showSomething.value = true
      })
    }

    const loadFormules = () => {
      Http.get('get-formules')
        .then(response => {
          formule.value = response.data

          // console.log('chargement des formules terminées')
          // console.log(formule.value)
          // eslint-disable-next-line no-use-before-define
          //loadOptions()
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
      Http.get('get-options')
        .then(response => {
          option.value = response.data
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const loadOptions = () => {
      Http.get('get-options')
        .then(response => {
          option.value = [...option.value, ...response.data]
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    return {
      sumAmountUpgrade,
      formule,
      option,
      loadFormules,
      loadOptions,
      contrat,
      paymentDetails,
      titre,
      amountToPay,
      signedDate,
      filename,
      customer,
      taxes,
      montantFormuleHT,
      montantOption,
      montantOptionHT,
      payments,
      billMonth,

      // invoiceDescription,
      printInvoice,
      getDeposit,
      getMontant,
      bond,
      amount,
      additionAmount,
      loading,
      dfinCachet,
      dfinSignature,
      logoGrossiste,
      dialog,
      page,
      withCurrency,
      decoders,
      showDispenseRestAmount,
      activatedIr,
      setSignature,
      setlogo,
      setCachet,

      currency,
      clientInfo,
      ticket,
      coef,
      discount,
      netPayable,
      isProforma,

      printBtn,
      downloadBtn,
      debt,
      decList,
      loadEtatDette,

      hasStartedGeneration,
      hasGenerated,

      // drawer
      isInvoiceSendSidebarActive,
      isAddPaymentSidebarActive,
      formatAmount,
      getDecodeur,
      decodeur,
      montant,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
//@import '~@core/preset/preset/apps/invoice.scss';

.bordure{
  border-left:1px solid #a8a5a5;
  border-bottom:1px solid #a8a5a5;

}
.bordure-droit{
  border-right: 1px solid #a8a5a5;
}

th.bordureTop{
  background: #e1dede !important;
}

@media screen {
  .footer {
    display: none;
  }
}

@media print {

  @page {
    size: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .page{
    margin-bottom: 100px !important;
  }
  .v-application {
    background: none !important;
  }

  #dont-see{
    display: none !important;
  }

  .footer {
    position: fixed;
    bottom: 0;
    margin-top: 100px !important;
    margin-bottom: 10px !important;
  }

  //.page-suivante {
  //  page-break-after: always;
  //  bottom: 0;
  //}

  .app-navigation-menu,
  .marquee-text,
  .v-app-bar,
  .v-footer,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .vert{
    color: #56ca00 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}

.bordered{
  border: 1px solid black;
  padding: 1rem;
  width: 300px;
  min-width: 300px;
}

.text-black {
  color: black;
}

.border{
  border: 1px solid black;
}
.border-left{
  border-left: 1px solid #d1d1d1;
}
.border-right{
  border-right: 1px solid #d1d1d1;
}

.border-bottom{
  border-bottom: 1px solid #d1d1d1;
}
</style>
